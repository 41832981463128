const PAYMENT = {
  TYPES: {
    ACH: 'ACH',
    CC: 'CC',
    EFT: 'EFT',
  },
};

Object.freeze(PAYMENT);

export default PAYMENT;
