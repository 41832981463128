import { Webhook } from '@services/public-api/webhook.dto';

export default {
  SET_WEBHOOKS: (state, webhooks = []) => {
    state.webhooks = webhooks.map((webhook) => new Webhook(webhook));
  },
  INSERT_WEBHOOK: (state, webhook) => {
    state.webhooks.push(webhook);
  },
  UPDATE_WEBHOOK: (state, webhook) => {
    const index = state.webhooks.findIndex(({ id }) => id === webhook.id);

    if (index !== -1) {
      state.webhooks.splice(index, 1, new Webhook(webhook));
    }
  },
  DELETE_WEBHOOK: (state, webhook) => {
    const index = state.webhooks.findIndex(({ id }) => id === webhook.id);

    if (index !== -1) {
      state.webhooks.splice(index, 1);
    }
  },
};
