import { publicApiService } from '@services';

import { Webhook } from '@services/public-api/webhook.dto';

export default {
  async fetchWebhooksList({ commit }) {
    const { publicApiWebhook = [] } = await publicApiService.list({
      names: ['publicApiWebhook'],
    });

    commit('SET_WEBHOOKS', publicApiWebhook);
  },
  async createWebhook({ state, commit }, data) {
    const webhook = Webhook.create(data);

    await publicApiService.update({
      name: 'publicApiWebhook',
      value: [...state.webhooks, webhook],
    });

    commit('INSERT_WEBHOOK', webhook);
  },
  async updateWebhook({ state, commit }, values) {
    const webhook = Webhook.update(values);

    commit('UPDATE_WEBHOOK', webhook);

    await publicApiService.update({
      name: 'publicApiWebhook',
      value: state.webhooks,
    });
  },
  async deleteWebhook({ state, commit }, webhook) {
    commit('DELETE_WEBHOOK', webhook);

    await publicApiService.update({
      name: 'publicApiWebhook',
      value: state.webhooks,
    });
  },
};
