import { v4 as uuidv4 } from 'uuid';

export class Webhook {
  constructor({ id, url = '', active = false, description = '', events = {}, headers = {} } = {}) {
    this.id = id;
    this.url = url;
    this.active = active;
    this.description = description;
    this.headers = headers;
    this.events = events;
  }

  static create(data = {}) {
    return new Webhook({ ...data, id: uuidv4() });
  }

  static update(data = {}) {
    return new Webhook({ ...data });
  }
}
