import { creditManagementService } from '@services';
import Vue from 'vue';

export default {
  fetchCreditRequests: async ({ commit }, params) => {
    try {
      const { data, total } = await creditManagementService.list(params);

      commit('SET_CREDIT_REQUESTS', data);
      commit('SET_TOTAL_CREDIT_REQUESTS', total);
    } catch (error) {
      console.warn(error);
    }
  },
  updateCreditRequest: async (_, params) => {
    try {
      const { id, ...rest } = params;
      const payload = {
        companyId: Vue.auth.user()?.selectedCompany?.id,
        ...rest,
      };

      await creditManagementService.update({ id, payload });
    } catch (error) {
      console.warn(error);
    }
  },
  async sendCreditRequest(_, { files, ...payload }) {
    try {
      const formData = new FormData();

      payload.companyId = this._vm.$auth.user()?.selectedCompany?.id;
      formData.append('json', JSON.stringify(payload));

      for (const file of files) {
        formData.append('file', file);
      }

      await creditManagementService.send(formData);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
  setSelected: ({ commit }, payload) => {
    commit('SET_SELECTED', payload);
  },
  fetchCreditReport: async ({ commit }, params) => {
    try {
      commit(
        'widgets/SET_LOADING_WIDGETS',
        {
          id: 'creditRequest',
          value: true,
        },
        { root: true },
      );

      return creditManagementService.report(params);
    } catch (error) {
      console.warn(error);
    } finally {
      commit(
        'widgets/SET_LOADING_WIDGETS',
        {
          id: 'creditRequest',
          value: false,
        },
        { root: true },
      );
    }
  },
  fetchCreditReportList: async ({ commit }, params) => {
    try {
      commit(
        'widgets/SET_LOADING_WIDGETS',
        {
          id: 'creditRequest',
          value: true,
        },
        { root: true },
      );

      return creditManagementService.reportList(params);
    } catch (error) {
      console.warn(error);
    } finally {
      commit(
        'widgets/SET_LOADING_WIDGETS',
        {
          id: 'creditRequest',
          value: false,
        },
        { root: true },
      );
    }
  },
  requestCreditReportCompanies: async (_, params) => {
    return creditManagementService.requestReportCompanies(params);
  },
  requestCreditReport: async ({ dispatch }, params) => {
    const res = await creditManagementService.requestReport(params);

    await dispatch(
      'settings/fetchAndUpdateCompanySetting',
      {
        companyId: Vue.auth.user()?.selectedCompany?.id,
        name: 'creditReportUsage',
      },
      { root: true },
    );

    return res;
  },
  requestPDFReport: async ({ dispatch }, params) => {
    const res = await creditManagementService.requestPDFReport(params);

    return res;
  },
  fetchCreditReports: async ({ commit }, params) => {
    try {
      const { data, total } = await creditManagementService.reportList(params);

      commit('SET_CREDIT_REPORTS', data);
      commit('SET_TOTAL_CREDIT_REPORTS', total);
    } catch (error) {
      console.warn(error);
    }
  },
  updateCreditReport: async (_, params) => {
    return creditManagementService.updateReport(params);
  },
  fetchBusinessCountries: async (_, params) => {
    try {
      return creditManagementService.businessCountriesList(params);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchSearchFields: async (_, params) => {
    try {
      return creditManagementService.searchFields(params);
    } catch ({ message }) {
      console.error(message);
    }
  },
  checkDirectConnection: async (_, params) => {
    try {
      return creditManagementService.checkDirectConnection(params);
    } catch ({ message }) {
      console.error(message);
    }
  },
};
