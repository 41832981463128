import Vue from 'vue';

import { httpClient } from '@api';

export default {
  async find(params) {
    return httpClient.post('/dispute/list', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
  async findOne(disputeId) {
    return httpClient.post(`/dispute/find-one/${disputeId}`, {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
    });
  },
  async create(payload) {
    return httpClient.post('/dispute/create', payload);
  },
  async update(payload) {
    return httpClient.post('/dispute/update', payload);
  },
  async getReasons() {
    return httpClient.post('/dispute/reasons', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
    });
  },
  async fetchDisputeReasonChart({ search }) {
    return httpClient.post('/dispute/chart', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      search,
      chartType: 'reasonsCount',
    });
  },
  async fetchDisputeAvgDaysChart({ search }) {
    return httpClient.post('/dispute/chart', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      search,
      chartType: 'daysInProgressAvg',
    });
  },
  async generateDemo(params) {
    return httpClient.post('dispute/demo/generate', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
};
