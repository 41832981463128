import { disputeManagementService } from '@services';

export default {
  setSelected: ({ commit }, selected) => {
    commit('SET_SELECTED', selected);
  },
  async find({ commit }, payload) {
    const { list = [], total = 0 } = await disputeManagementService.find(payload);

    commit('SET_ITEMS', list);
    commit('SET_SERVER_ITEMS_LENGTH', total);
  },
  async create(_, { payload, files }) {
    const formData = new FormData();

    formData.append('json', JSON.stringify(payload));

    for (const file of files) {
      formData.append('file', file);
    }

    return disputeManagementService.create(formData);
  },
  async update(_, { disputeId, updatedFields, attachmentNumber = 0, files = [] }) {
    const formData = new FormData();

    const payload = {
      companyId: this._vm.$auth.user()?.selectedCompany?.id,
      disputeId,
      updatedFields,
      attachmentNumber,
    };

    formData.append('json', JSON.stringify(payload));

    if (attachmentNumber > 0) {
      for (const file of files) {
        formData.append('file', file);
      }
    }

    return disputeManagementService.update(formData);
  },
  async export(_, payload) {
    return disputeManagementService.find(payload);
  },
  async findOne(_, disputeId) {
    return disputeManagementService.findOne(disputeId);
  },
  async getReasons(_) {
    return disputeManagementService.getReasons();
  },
};
