export default {
  topFilters: {
    invoices: [],
    customers: [],
    dashboard: [],
    bankReconciliation: [],
    disputeManagement: [],
    activityLog: [],
  },
};
